import { Injectable } from '@angular/core';
import { DOMAIN_ACCOUNTING } from '@app/data/constants/domains';
import { ILedger } from '@app/data/models/ILedger';
import { ServerErrorService } from '@core/services/server-error.service';
import { mapToCriteriaResponse } from '@core/utils/map-to-criteria-response';
import { ICriteriaResponse } from '@models/ICriteriaResponse';
import { DatabaseProvider } from '@paella-front/ngx-database';
import { Database } from '@paella-front/ngx-database/lib/database';
import {
  HttpCriteria,
  SuperHttpClientProvider,
} from '@paella-front/ngx-super-http-client';
import { BehaviorSubject, Observable } from 'rxjs';
const ACCOUNTS_COMPANY = 'accounts_company';

@Injectable({
  providedIn: 'root',
})
export class ExerciseService {
  _ledger$: BehaviorSubject<ILedger | null> = new BehaviorSubject<ILedger | null>(null);
  _ledgerData$ = new BehaviorSubject<Array<ILedger> | null>(null);
  _refreshLedger$ = new BehaviorSubject<boolean>(false);
  private readonly $accounting: ReturnType<SuperHttpClientProvider['use']>;

  /////////////////
  // Constructor //
  /////////////////

  constructor(
    private $db: DatabaseProvider,
    $$shttp: SuperHttpClientProvider,
    public $serverError: ServerErrorService
  ) {
    this.$accounting = $$shttp.use(DOMAIN_ACCOUNTING);
  }

  ///////////////////////
  // Private Accessors //
  ///////////////////////

  ///////////////////////
  // Public Accessors //
  ///////////////////////

  get ledger$(): Observable<ILedger | null> {
    return this._ledger$.asObservable();
  }

  get ledgers$(): Observable<Array<ILedger | null>> {
    return this._ledgerData$.asObservable();
  }

  get refreshEmployees$(): Observable<boolean> {
    return this._refreshLedger$.asObservable();
  }

  set ledger(data: ILedger | null) {
    this._ledger$.next(data);
    this.setGroupAccountsSelected(data);
  }

  set ledgers(data: Array<ILedger> | null) {
    this._ledgerData$.next(data);
  }

  set refreshEmployees(value: boolean) {
    this._refreshLedger$.next(value);
  }

  async setGroupAccountsSelected(value: ILedger): Promise<void> {
    await (this.$db.default as Database).set(ACCOUNTS_COMPANY, value);
  }

  async getGroupAccountsSelected(): Promise<ILedger> {
    return await (this.$db.default as Database).get(ACCOUNTS_COMPANY);
  }

  list(
    criteria: HttpCriteria,
    companyId: number
  ): Observable<ICriteriaResponse<ILedger>> {
    return this.$accounting
      .filter(`/ledger/${companyId}/search`, criteria, { observe: 'response' })
      .pipe(mapToCriteriaResponse());
  }

  get(id: number, companyId: number): Observable<ILedger> {
    return this.$accounting.get<ILedger>(`/ledger/${companyId}/${id}`);
  }

  create(data: ILedger): Observable<ILedger> {
    return this.$accounting.post<ILedger>(`/ledger/${data.companyId}`, data);
  }

  edit(id: number, data: ILedger): Observable<ILedger> {
    return this.$accounting.put<ILedger>(`/ledger/${id}/${data.id}`, data);
  }

  delete(id: number, companyId: number, value: number = 1): Observable<boolean> {
    return this.$accounting.delete<boolean>(`/ledger/${companyId}/${id}/${value}`);
  }

  deletePhysically(id: number, companyId: number): Observable<ILedger> {
    return this.$accounting.delete<ILedger>(`/ledger/${companyId}/${id}`);
  }
}
